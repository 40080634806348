@import 'scss/core/variables.scss';

.tabs {
  display: flex;
  flex-direction: column;
  width: 100%;

  .tab {
    background-color: $color-primary-blue-dark;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    min-height: 3rem;
    height: 100%;
    padding: 0 1rem;
    display: flex;
    align-items: center;
    a {
      color: $color-primary-white;
      font-weight: bold;
    }

    &.tab-checked {
      background-color: $color-primary-white;
      border: solid 1px $color-primary-blue-light;
      margin-right: 0.5rem;
      height: 4.7rem;
      a {
        color: $color-primary-blue-dark;
      }
    }
  }
}
.tab-body {
  border-top: 0;
}
.container-ics-logo {
  display: flex;
  align-items: start;
  width: 100%;
  margin-left: 0px !important;
}

@media (min-width: 768px) {
  .container-ics-logo {
    align-items: start;
  }
  .tabs {
    flex-direction: row;
    border-bottom: solid 1px $color-primary-blue-light;

    .tab.tab-checked {
      margin-bottom: -1px;
      margin-top: -10px;
      border-bottom: none;
    }
  }
}
